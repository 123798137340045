let idComponent = 0;
const uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);

export const currentId = (id: string) => {
  if (id?.length > 0) return id;
  idComponent++;

  const numbersId = +uniqueId.replace(/[^0-9]/g, '');

  const numberComponent = (numbersId * idComponent).toString();

  return uniqueId + numberComponent;
};
